import React from 'react'
import { Link } from 'react-router-dom'
import { useInView } from 'react-intersection-observer'

const Copyright = () => {
  const { ref: aRef, inView: aView} = useInView();
  const { ref: bRef, inView: bView} = useInView();
  const { ref: cRef, inView: cView} = useInView();
  const { ref: dRef, inView: dView} = useInView();
  const { ref: eRef, inView: eView} = useInView();
  return (
    <>
    <div className="copyrights">
        <div className="copyright-cont">
            <div className="cpr">
                <h5 className={"anihideLeft" + (aView ? " anishowRight" : " ")} style={{ "--i" : "3"}} ref={aRef}>© 2024 First Franklin Financial Corporation <span> All rights reserved</span></h5>
                <ul>
                    <li className={"anihideDown" + (bView ? " anishowUp" : " ")} style={{ "--i" : "5"}} ref={bRef}><Link to={`/`}>Privacy</Link></li>
                    <li className={"anihideDown" + (cView ? " anishowUp" : " ")} style={{ "--i" : "6"}} ref={cRef}><Link to={`/`}>Terms & Condition</Link></li>
                    <li className={"anihideDown" + (dView ? " anishowUp" : " ")} style={{ "--i" : "7"}} ref={dRef}><Link to={`/support`}>Support</Link></li>
                    <li className={"anihideDown" + (eView ? " anishowUp" : " ")} style={{ "--i" : "8"}} ref={eRef}><Link to={`/support`}>Contact</Link></li>
                </ul>
            </div>
        </div>
    </div>
    </>
  )
}

export default Copyright