import React from 'react'
import { Link } from 'react-router-dom'
import { useInView } from 'react-intersection-observer'

import '../styles/links.css'
const Links = () => {
  const { ref: aRef, inView: aView} = useInView();
  const { ref: bRef, inView: bView} = useInView();
  const { ref: cRef, inView: cView} = useInView();
  const { ref: dRef, inView: dView} = useInView();
  const { ref: eRef, inView: eView} = useInView();
  const { ref: fRef, inView: fView} = useInView();
  const { ref: gRef, inView: gView} = useInView();
  const { ref: hRef, inView: hView} = useInView();
  const { ref: iRef, inView: iView} = useInView();
  const { ref: jRef, inView: jView} = useInView();
  const { ref: kRef, inView: kView} = useInView();
  const { ref: lRef, inView: lView} = useInView();
  const { ref: mRef, inView: mView} = useInView();
  const { ref: nRef, inView: nView} = useInView();
  const { ref: oRef, inView: oView} = useInView();
  const { ref: pRef, inView: pView} = useInView();
  const { ref: qRef, inView: qView} = useInView();
  return (
    <>
      <div className="footer-links">
        <div className="linked">
          <h3 className={"anihideDown" + (aView ? " anishowUp" : " ")} style={{ "--i" : "4"}} ref={aRef}>Presentation</h3>
          <ul>
            <li className={"anihideDown" + (bView ? " anishowUp" : " ")} style={{ "--i" : "5"}} ref={bRef}>
              <Link to={`/`}>Home</Link>
            </li>
            <li className={"anihideDown" + (cView ? " anishowUp" : " ")} style={{ "--i" : "6"}} ref={cRef}>
              <Link to={`/`}>Who are we ?</Link>
            </li>
            <li className={"anihideDown" + (dView ? " anishowUp" : " ")} style={{ "--i" : "7"}} ref={dRef}>
              <Link to={`/`}>Testimonials</Link>
            </li>
          </ul>
        </div>
        <div className="linked">
          <h3 className={"anihideDown" + (eView ? " anishowUp" : " ")} style={{ "--i" : "4"}} ref={eRef}>Our Services</h3>
          <ul>
            <li className={"anihideDown" + (fView ? " anishowUp" : " ")} style={{ "--i" : "5"}} ref={fRef}>
              <Link to={`/sign-in`}>Our Accounts</Link>
            </li>
            <li className={"anihideDown" + (gView ? " anishowUp" : " ")} style={{ "--i" : "6"}} ref={gRef}>
              <Link to={`/sign-in`}>Savings</Link>
            </li>
            <li className={"anihideDown" + (hView ? " anishowUp" : " ")} style={{ "--i" : "7"}} ref={hRef}>
              <Link to={`/sign-in`}>Insurance</Link>
            </li>
            <li className={"anihideDown" + (iView ? " anishowUp" : " ")} style={{ "--i" : "8"}} ref={iRef}>
              <Link to={`/sign-in`}>Get a loan</Link>
            </li>
          </ul>
        </div>
        <div className="linked">
          <h3 className={"anihideDown" + (jView ? " anishowUp" : " ")} style={{ "--i" : "5"}} ref={jRef}>Other Links</h3>
          <ul>
            <li className={"anihideDown" + (kView ? " anishowUp" : " ")} style={{ "--i" : "6"}} ref={kRef}>
              <Link to={`/sign-in`}>Create an account</Link>
            </li>
            <li className={"anihideDown" + (lView ? " anishowUp" : " ")} style={{ "--i" : "7"}} ref={lRef}>
              <Link to={`/sign-in`}>To log in</Link>
            </li>
            <li className={"anihideDown" + (mView ? " anishowUp" : " ")} style={{ "--i" : "8"}} ref={mRef}>
              <Link to={`/sign-in`}>Check a deposit</Link>
            </li>
            <li className={"anihideDown" + (nView ? " anishowUp" : " ")} style={{ "--i" : "9"}} ref={nRef}>
              <Link to={`/sign-in`}>Credit simulator</Link>
            </li>
          </ul>
        </div>
        <div className="linked">
          <h3 className={"anihideDown" + (oView ? " anishowUp" : " ")} style={{ "--i" : "5"}} ref={oRef}>information</h3>
          <ul>
            <li className={"anihideDown" + (pView ? " anishowUp" : " ")} style={{ "--i" : "6"}} ref={pRef}>
              <Link to={`/`}>208 Finsbur Circus</Link>
            </li>
            <li className={"anihideDown" + (qView ? " anishowUp" : " ")} style={{ "--i" : "7"}} ref={qRef}>
            <a href="tel:+18185389931">+18185389931</a>
            </li >
          </ul>
        </div>
      </div>
    </>
  )
}

export default Links