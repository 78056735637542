import React, { useState, useEffect } from 'react'
import { Link, useLocation } from "react-router-dom";

import '../styles/footer.css'

import { AiOutlineProfile, AiOutlineTransaction } from "react-icons/ai";
import { GiCancel } from "react-icons/gi";
import { FiChevronDown } from "react-icons/fi";
import { BsMenuUp, BsPersonCircle, BsCreditCard } from 'react-icons/bs'

const Footer = ({user, logout, unread}) => {
  
  const [menu, setMenu] = useState(false);
  const [profile, setProfile] = useState(false);
  const [Tranzact, setTransact] = useState(false);
  const location = useLocation();
  const [urlTag, setUrlTag] = useState("")

  useEffect(() => {
    const pathname = location.pathname.split("/");
    if(pathname.includes('transactions')){
      setUrlTag("transactions")
    } else if(pathname.includes('profile')){
      setUrlTag("profile")
    }  else if(pathname.includes('card')){
      setUrlTag("card")
    }  else if(pathname.includes('liveChat')){
      setUrlTag("liveChat")
    } else {
      setUrlTag("menu")
    }
  }, [location.pathname])


  return (
    <>
      <div className="dashboard-footer">
        <div className="cont">
          <ul>
            <li className="navToggler" onClick={(e) => setMenu(!menu)} id="navToggle">
                <Link>
                <AiOutlineProfile /> 
                <h3>Menu</h3>
                </Link>
            </li>
            <li className={(urlTag === "transactions") ? "active" : " "}>
              <Link to={`/${user?.result?.userName}/transactions`} >
                <AiOutlineTransaction />
                <h3>Transactions</h3>
              </Link>
            </li>
            <li className={(urlTag === "profile") ? "active" : " "}>
              <Link to={user ? `/${user?.result?.userName}/profile` : "/auth"} >
                <BsPersonCircle />
                <h3>Profile</h3>
              </Link>
            </li>
            <li className={(urlTag === "card") ? "active" : " "}>
              <Link to={`/${user?.result?.userName}/card`} >
                <BsCreditCard />
                <h3>Card</h3>
              </Link>
            </li>
            <li className={(urlTag === "dashboard") ? "active" : " "}>
              <>
              </>
            </li>
          </ul>
        </div>
        <div className={"nav-menu" + (menu ? " activeMobileNav" : " ")} >
        <div className="inner-cont">
          <div onClick={() => setMenu(false)} id="navClose">
            <h3><GiCancel /></h3>
          </div><>
                <ul className="nav-links mob">
                  <li onClick={() => setMenu(false)}><Link to={`/${user?.result?.userName}/dashboard`}> Dashboard </Link></li>
                  <li onClick={() => setMenu(false)}><Link to={`/${user?.result?.userName}/liveTrade`}> Live Trade </Link></li>
                  <li onClick={(e) => setTransact(!Tranzact)}><Link>Transactions <FiChevronDown /></Link> </li>
                  <ul className={Tranzact ? "tranzact-mob-menu" : " "}>
                    <li onClick={() => setMenu(false)}><Link to={`/${user?.result?.userName}/withdrawal`}> Withdrawals </Link></li>
                    <li onClick={() => setMenu(false)}><Link to={`/${user?.result?.userName}/transfer`}> Transfer </Link></li>
                    <li onClick={() => setMenu(false)}><Link to={`/${user?.result?.userName}/transactions`}> History </Link></li>
                  </ul>

                  <li onClick={(e) => setProfile(!profile)} className={profile ? "mob-menu-bg" : " "}><Link > Account <FiChevronDown /> </Link></li>
                  <ul className={profile ? "prof-mob-menu" : " "}>
                    <li onClick={() => setMenu(false)}><Link to={`/${user?.result?.userName}/profile-setting`} >Profile Settings</Link></li>
                    <li onClick={() => setMenu(false)}><Link to={`/${user?.result?.userName}/change-password`} >Change Password</Link></li>
                  </ul>

                  <li onClick={() => setMenu(false)}><Link to="/support"> Support </Link></li>
                  <li onClick={() => setMenu(false)}><Link onClick={logout} >Log Out</Link></li>
                </ul>
              </>
        </div>
      </div>
      </div>
    </>
  )
}

export default Footer