import React, { useState } from 'react'
import { useSelector } from "react-redux";
import moment from 'moment';
import { Link } from 'react-router-dom'

import '../styles/transaction.css'
import TransDets from './TransDets';

const Transactions = () => {
  const { account } = useSelector((state) => state.accounts);
  const [isConfirm, setIsConfirm] = useState(false);
  const [currentRept, setCurrentRept] = useState([]);

  let USDollar = new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'USD',
  });

  const Tranxact = async (option) => {
    setIsConfirm(true);
    setCurrentRept({ option })
  }

  const Cancel = async (e) => {
    e.preventDefault();
    setIsConfirm(false);
  }

  console.log(isConfirm)
  console.log(currentRept)
  return (
    <>
      <div className="trans">
        <div className="trans-header">
          <h2>History</h2>
        </div>
        <div className="trans-container">
          {!account?.transaction?.all?.length ? "Loading..." : (
            <div className="mobile-trans">
              {account.transaction.all.sort((a, b) => {
                return (
                  new Date(b.date).valueOf() -
                  new Date(a.date).valueOf()
                );
              }).map((option, index) => (
                <div className="trans-list">
                  <div className="lft">
                    <h4 >{moment(option?.date).format('LLL')}</h4>
                    <p>FFFC/{option?._id}</p>
                    <h4 className='rec-name'>{option?.recName}, {option?.datails} </h4>
                  </div>
                  <div className="cntr">
                    <p>Amount</p>
                    <h3 className={option?.alertType === "Debit" ? "red" : "grn"}>{option?.alertType === "Debit" ? "-" : "+"}{USDollar.format(option?.total)}</h3>
                    <p className={"statz " + option?.status}>{option?.status}</p>
                  </div>
                  <div className="rgt">
                    <h3 className={option?.alertType === "Debit" ? "red" : "grn"}>{option?.alertType}</h3>
                    <p onClick={() => Tranxact(option)} className="reptDets">Full Details</p>
                  </div>
                </div>
              ))}
            </div>
          )}
        </div>
        {
          isConfirm &&
          <TransDets currentRept={currentRept} Cancel={Cancel} setCurrentRept={setCurrentRept} />
        }
      </div>
    </>
  )
}

export default Transactions