import citifirst from "./citifirst.png";
import goldlogo from "./goldlogo.png";
import ffic from "./ffic.png";

const IMGS = {
  citifirst: citifirst,
  goldlogo: goldlogo,
  ffic: ffic
};

export default IMGS;